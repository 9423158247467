import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
/**
 * Created by zhangchao on 19/5/8.
 */

import axios from 'axios';
import { getWxAuth, getAliAuth } from './wechat';
import Cookies from 'js-cookie';
import { sendCode, sendAliCode } from '@/api/app';
import { isWechatBrowser, isAliPayBrowser, isAliPayLife } from '@/utils/index';
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // api 的 base_url
  timeout: 30000
});

//请求不需要携带token的url
var unNeedTokenURL = ['/wx/user/login', '/wx/user/alipayLive/auth', '/wx/user/alipayLive/login'];
var loading;
function startLoading() {
  loading = _Toast.loading({
    message: '加载中...',
    forbidClick: true
  });
}
function endLoading() {
  loading.clear();
}
service.interceptors.request.use(function (config) {
  // console.log(config);
  if (config.url.indexOf('/wx/user/login') === -1 || config.url.indexOf('/wx/user/alipayLive/auth') === -1 || config.url.indexOf('/wx/user/alipayLive/login') === -1) {
    config.headers['token'] = Cookies.get('token');
  }

  // if(unNeedTokenURL.indexOf(config.url)>-1){
  //   config.headers['token'] = Cookies.get('token') 
  // }
  // console.log(config,"配置")
  // 如果是查询订单就不必传递时间戳了
  config.data = {
    value: config.data,
    timestamp: config.url === '/wx/order/user' ? '' : Date.now()
  };
  if (config.data.value && config.data.value.page) {
    var page = config.data.value.page;
    delete config.data.value.page;
    if (Object.keys(config.data.value).length === 1) {
      config.data.value = Object.values(config.data.value)[0];
    }
    config.data.page = page;
  }
  return config;
}, function (error) {
  // Do something with request error
  _Toast.clear();
  console.log('😭😭😭😭😭😭', '请求出错啦：', error); // for debug
  _Dialog({
    message: error.message,
    title: '请求错误'
  });
  Promise.reject(error);
});
service.interceptors.response.use(function (response) {
  // Toast.clear()
  Cookies.set('token', response.headers.token, {
    expires: 1 / 24
  });
  console.log('aaa');
  var res = response.data;
  // alert(JSON.stringify(response))
  // alert(JSON.stringify(response.headers))
  return res.code !== '00' ? errorCaptured(res) : res;
}, function (error) {
  _Toast.clear();
  var response = error.response;
  if (response) {
    errorCaptured(response.data);
  } else {
    _Dialog({
      message: '当前网络异常，请重新尝试或者联系客服协助处理！',
      title: '网络异常'
    });
    throw Error(error);
  }
});
function errorCaptured(res) {
  var error = {
    'JU402': {
      message: '当前用户不在线',
      href: ''
    },
    'JU500': {
      message: 'code过期啦',
      href: ''
    },
    'JU403': {
      message: '对不起，您无权限访问该页面！',
      href: '403'
    },
    'JU404': {
      message: '找不到该页面啦！',
      href: '404'
    },
    'WX0005': {
      message: '请先关注公众号在访问哦！',
      href: 'leadFollow'
    },
    // 'JU302': { message: '对不起，服务器正在维护！', href: 'serveDead' },
    'JU302': {
      message: '对不起，服务器正在维护！',
      href: 'browser'
    },
    'JU430': {
      message: '网络异常，请稍候再试！',
      href: 'networkError'
    }
  };
  if (error[res.code]) {
    // alert(error[res.code])
    if (res.code === 'JU402') {
      if (isWechatBrowser) {
        // 如果在微信端出现402错误
        getWxAuth({
          path: '/home'
        });
      } else if (isAliPayBrowser) {
        if (window.location.href.indexOf('/home')) {
          getAliAuth({
            path: '/home'
          });
        } else {
          getAliAuth({
            path: '/user'
          });
        }
      } else {
        sendCode().then(function () {
          window.location.reload();
        });
      }
    } else if (res.code === 'JU302') {
      window.location.href = "https://oss.globalcharge.cn/prod/wechat/open/index_alipay.html";
    } else {
      // alert('请求出现错误')
      // alert(res.code)
      window.location.href = process.env.VUE_APP_FILE_PATH + error[res.code].href;
    }
    throw Error(error[res.message]);
  }
  console.error('😭😭😭😭😭😭', '接口出错啦：', res);
  if (isAliPayLife && res.message === '未关注公众号') {
    //如果是支付宝生活号，点击开通会员出现未关注公众号提示，则弹出带公众号土图片的对话框
    _Dialog({
      message: res.message + '<img src="https://globalcharge.oss-cn-hongkong.aliyuncs.com/prod/wechat/src/assets/public/gongZhongHao.jpg" style="width:200px;height:200px;"/>',
      title: '提示'
    });
  } else {
    _Dialog({
      message: res.message,
      title: '提示'
    });
  }
  throw Error(res);
}
export default service;