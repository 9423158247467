import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
// 引入
import CryptoJS from 'crypto-js';

// 密钥
var AES_KEY = 'hVmYq3t6w9z$C&F)'; // 后端提供

// 加密
export function encrypt(_ref) {
  var word = _ref.word;
  var key = CryptoJS.enc.Utf8.parse(AES_KEY);
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

// 解密
export function decrypt(_ref2) {
  var word = _ref2.word;
  var key = CryptoJS.enc.Utf8.parse(AES_KEY);
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}