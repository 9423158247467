import _objectSpread from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.sub.js";
/**
 * Created by zhangchao on 19/5/8.
 */
import Cookies from 'js-cookie';
import JWT from 'jsonwebtoken';
import SHA1 from 'sha1';
import { KF_QR_CODE } from '../assets/AllData/globalData';
// eslint-disable-next-line no-unused-vars
import store from '@/store';

//获取当前utc时间戳
export function getUTCTime() {
  var d1 = new Date();
  var d2 = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds());
  return Date.parse(d2);
}

// 将2022-21-12 14:15:16 转化为 2022-21-12 14:15:16 本地化时间
export function dateToDate(value) {
  var value2 = value.replace(/-/g, '/');
  var date = new Date(value2);
  var timeStr = date.getFullYear() + '-';
  if (date.getMonth() < 9) {
    //月份从0开始的
    timeStr += '0';
  }
  timeStr += date.getMonth() + 1 + '-';
  timeStr += date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  timeStr += ' ';
  timeStr += date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  timeStr += ':';
  timeStr += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  timeStr += ':';
  timeStr += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return timeStr;
}
export function toFixed2(value) {
  return Number(value).toFixed(2);
}
export function toFixed0(value) {
  return Number(value).toFixed(0);
}

// 获取当前时间2019-05
export function getYearAndMonth(userDate) {
  var date = userDate || new Date();
  var seperator = '-';
  var month = date.getMonth() + 1;
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  var currentdate = date.getFullYear() + seperator + month;
  return currentdate;
}

// 获取当前完整时间2019-05-17 14:58:11
export function getFullDate(userDate) {
  var date = userDate || new Date();
  var seperator1 = '-';
  var seperator2 = ':';
  var seperator3 = ' ';
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var strhour = date.getHours();
  var strmin = date.getMinutes();
  var strsec = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate;
  }
  if (strhour >= 0 && strhour <= 9) {
    strhour = '0' + strhour;
  }
  if (strmin >= 0 && strmin <= 9) {
    strmin = '0' + strmin;
  }
  if (strsec >= 0 && strsec <= 9) {
    strsec = '0' + strsec;
  }
  var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + seperator3 + strhour + seperator2 + strmin + seperator2 + strsec;
  return currentdate;
}

// 获取当前时间2019-11-27
export function getToday(userDate) {
  var date = userDate || new Date();
  var seperator = '-';
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate;
  }
  var currentdate = date.getFullYear() + seperator + month + seperator + strDate;
  return currentdate;
}

// 获取一个月的最后时刻2019-11-30 23:59:59
export function getMonthLastTime(userDate) {
  var date = userDate || new Date();
  var seperator1 = '-';
  var seperator2 = ' ';
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = getMonthLastDay(date);
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + seperator2 + '23:59:59';
  return currentdate;
}

//将时间戳转换成2023-05-10 10：00：00格式
export function getFormDateTime(time) {
  // 检测time 是否是一个数字
  var bool = /^-?\d+$/.test(String(time));
  var data;
  if (bool) {
    data = new Date(time);
  } else {
    data = new Date();
  }
  var year = data.getFullYear();
  var month = data.getMonth() + 1;
  var day = data.getDate();
  var hour = data.getHours();
  var minutes = data.getMinutes();
  var second = data.getSeconds();
  hour = hour >= 10 ? hour : '0' + hour;
  minutes = minutes >= 10 ? minutes : '0' + minutes;
  second = second >= 10 ? second : '0' + second;
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + second;
}
export function getMonthLastDay(userDate) {
  var date = userDate || new Date();
  // 获取当月最后一天
  // const [year, month] = date.toLocaleString().split('/')
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  return new Date(year, month, 0).getDate();
  // 获取当月最后一天的当前时刻
  // date.setMonth(date.getMonth() + 1, 0)
  // return date
}
// 订单状态码
export function orderStatus(orderStatus) {
  var status = {
    'processing': {
      text: '充值中..',
      color: '#ef8b0a'
    },
    'success': {
      text: '充值成功',
      color: '#1ac150'
    },
    'fail': {
      text: '充值失败',
      color: '#999999'
    },
    'exception': {
      text: '充值中...',
      color: '#ef8b0a'
    },
    'pay': {
      text: '充值中...',
      color: '#ef8b0a'
    },
    'confirm': {
      text: '充值中...',
      color: '#ef8b0a'
    },
    'close': {
      text: '订单关闭',
      color: '#999999'
    },
    'new_order': {
      text: '待支付',
      color: 'rgb(255, 196, 78)'
    }
  };
  return status[orderStatus];
}
//投诉进度状态码
export function complainStatus(complainStatus) {
  var status = {
    'NEW': {
      color: 'rgb(255, 196, 78)',
      text: '待处理'
    },
    'PROCESSING': {
      color: 'rgb(255, 196, 78)',
      text: '处理中...'
    },
    'FINISHED': {
      color: '#1ac150',
      text: '客服已处理'
    },
    //如果method为true,则出现查看审核信息按钮
    'RESOLVED': {
      color: 'rgb(255, 196, 78)',
      text: '满意'
    },
    'UNRESOLVED': {
      color: 'rgb(255, 196, 78)',
      text: '不满意'
    }
  };
  return status[complainStatus];
}

//使用外部的qrcode.jpg
export function serveLink(href) {
  console.log(isPC());
  if (href) {
    if (href.indexOf('http') >= 0) {
      window.location.href = href;
    } else {
      this.$router.push({
        path: href
      });
    }
  } else {
    this.$dialog.alert({
      title: '专属客服',
      showConfirmButton: false,
      closeOnPopstate: true,
      closeOnClickOverlay: true,
      // message: `<img width="64%" src="${require('@@/public/qrcode.jpg')}" />\n长按图片扫描二维码\n或者搜索微信号：yqq-NO2`
      message: "<img width=\"64%\" src=\"".concat(KF_QR_CODE, "\" />\n").concat(isPC() ? '扫码联系客服' : '长按图片识别二维码')
    });
  }
}
export function randomArray(data) {
  var arr = data.map(function (v) {
    return v;
  });
  var i = arr.length;
  while (i) {
    var j = Math.floor(Math.random() * i--);
    var _ref = [arr[i], arr[j]];
    arr[j] = _ref[0];
    arr[i] = _ref[1];
  }
  return arr;
}
export function getSHA1UserInfo() {
  // "sub": "昵称",
  // "aud": "kumquat",
  // "unionId": "unionId",
  // "openId": "openId",
  // "iss": "tony",
  // "exp": 1585705379,
  // "iat": 1585701779,
  // "jti": "95"
  var userInfo = JWT.decode(Cookies.get('token'));
  console.log(userInfo, "JWT解析token获取用户信息");
  return _objectSpread(_objectSpread({}, userInfo), {}, {
    openIdSHA1: SHA1(userInfo.openId)
  });
}

// // 用户是否登录
// export function getUserLoginStatus(){
//   const {...userInfo} = JWT.decode(Cookies.get('token'))
//   console.log(" user Info --------------------------------------" , userInfo);
//   if( userInfo.sub > 0 && (userInfo.role == "ATTENTION" || userInfo.role == "USER_INFO" ) ){
//     store.commit("setIsLoginUser", true);
//     return true ;
//   }else{
//     return false ; 
//   }
// }

// 用户是否登录
export function getUserLoginStatus() {
  var token = Cookies.get('token');

  // 检查 token 是否存在，如果不存在，直接返回 false
  if (!token) {
    return false;
  }

  // 安全地解码 token，考虑到 decode 可能返回 null
  var userInfo = JWT.decode(token) || {}; // 如果 decode 返回 null，使用空对象作为默认值

  console.log("User Info --------------------------------------", userInfo);

  // 检查 userInfo 是否具有有效的 sub 和 role 属性
  if (userInfo.sub > 0 && (userInfo.role === "ATTENTION" || userInfo.role === "USER_INFO")) {
    store.commit("setIsLoginUser", true);
    return true;
  } else {
    return false;
  }
}

// 过滤数组中的重复元素
export function unique(array) {
  var n = []; //一个新的临时数组
  //遍历当前数组
  for (var i = 0; i < array.length; i++) {
    //如果当前数组的第i已经保存进了临时数组，那么跳过，
    //否则把当前项push到临时数组里面
    if (n.indexOf(array[i]) == -1) {
      n.push(array[i]);
    }
  }
  return n;
}

// 路由后带国家参数处理
import { getCountryList } from '@/api/home';
export function handleRouterCountry(code) {
  var country = '';
  var cname = '';
  getCountryList().then(function (res) {
    country = res.result.countryList;
    country.map(function (item) {
      if (code == item.countryCode) {
        cname = item.cname;
        // console.log(cname)
        localStorage.setItem('routerCountry', JSON.stringify(item));
      }
    });
  });
  return cname;
}
// 过滤数组中的空值
export function filterNullArray(date) {
  var newArray = [];
  for (var index = 0; index <= date.length; index++) {
    if (date[index]) {
      newArray.push(date[index]);
    }
  }
  return newArray;
}
export function scrollToBottom() {
  this.$nextTick(function () {
    window.scrollTo(0, document.documentElement.clientHeight + 100);
  });
}
export var paymentOrderUrl = process.env.VUE_APP_AUTH_URL + '/home/order/payment';

//判断当前环境是否是微信环境
export var isWechatBrowser = navigator.userAgent.toLowerCase().indexOf('micromessenger') >= 0;

//判断是否是PC 即是是用于浏览器
export function isPC() {
  var ua = navigator.userAgent;
  var isWindowsPhone = /(?:Windows Phone)/.test(ua);
  var isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  var isAndroid = /(?:Android)/.test(ua);
  var isFireFox = /(?:Firefox)/.test(ua);
  var isChrome = /(?:Chrome|CriOS)/.test(ua);
  var isTablet = /(?:iPad|PlayBook)/.test(ua) || isAndroid && !/(?:Mobile)/.test(ua) || isFireFox && /(?:Tablet)/.test(ua);
  var isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  var isPc = !isPhone && !isAndroid && !isSymbian;
  if (isAndroid || isPhone) {
    // console.log("手机")
    return false;
  } else if (isTablet) {
    // console.log("平板")
    return false;
  } else if (isPc) {
    // console.log("电脑")
    return true;
  } else {
    //除了手机与平板，其他都显示二维码
    return true;
  }
}
export var isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
export var isWindowBrowser = navigator.userAgent.toLowerCase().indexOf('windowswechat') >= 0;
export var isIOSPCBrowser = navigator.userAgent.toLowerCase().indexOf('macwechat') >= 0;
export var isMobileBrowser = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
export var isAliPayBrowser = navigator.userAgent.toLowerCase().indexOf('alipay') > -1;
export var isAliPayLife = navigator.userAgent.toLowerCase().indexOf('alipay') > -1 && navigator.userAgent.toLowerCase().indexOf('miniprogram') === -1;
export var isAliPayMinProgram = navigator.userAgent.toLowerCase().indexOf('alipay') > -1 && navigator.userAgent.toLowerCase().indexOf('miniprogram') > -1;